<template>
  <div class="container">
    <!-- <img style="width:100%;margin-top:13px;" src="../assets/news.png" alt=""> -->
    <div class="main">
      <div class="tab-title">
        您所在的位置：首页 > 最新资讯 >
        <span style="color: #0078cc">公司简介</span>
      </div>
      <div class="content">
        <div class="left">
          <div
            style="
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 8px 28px;
              border-bottom: 2px solid #0078cc;
            "
          >
            <img
              src="../assets/icon_heart.png"
              style="width: 18px; height: 18px"
              alt=""
            />
            <div
              style="
                font-weight: bold;
                color: #000;
                font-size: 15px;
                margin-left: 5px;
              "
            >
              汇采药文化
            </div>
          </div>
          <div
          @click='reload'
            style="
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 8px 36px;
              border-bottom: 1px solid #ddd;
              cursor: pointer;
            "
            class='active-class'
          >
            <img
              src="../assets/icon_earth.png"
              style="width: 18px; height: 18px"
              alt=""
            />
            <div style="font-size: 13px; margin-left: 5px">
              公司简介
            </div>
          </div>
          <!-- <div
            style="
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 8px 36px;
              border-bottom: 1px solid #ddd;
              background: #f2f2f2;
            "
          >
            <img
              src="../assets/icon_msg.png"
              style="width: 18px; height: 18px"
              alt=""
            />
            <div style="color: #333; font-size: 13px; margin-left: 5px">
              组织架构
            </div>
          </div> -->
          <!-- <div
            style="
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 8px 36px;
              border-bottom: 1px solid #ddd;
              background: #f2f2f2;
            "
          >
            <img
              src="../assets/icon_msg.png"
              style="width: 18px; height: 18px"
              alt=""
            />
            <div style="color: #333; font-size: 13px; margin-left: 5px">
              管理团队
            </div>
          </div> -->
          <!-- <div
            style="
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 8px 36px;
              border-bottom: 1px solid #ddd;
              background: #f2f2f2;
            "
          >
            <img
              src="../assets/icon_msg.png"
              style="width: 18px; height: 18px"
              alt=""
            />
            <div style="color: #333; font-size: 13px; margin-left: 5px">
              公司荣誉
            </div>
          </div> -->
          <!-- <div
            style="
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 8px 36px;
              border-bottom: 1px solid #ddd;
              background: #f2f2f2;
            "
          >
            <img
              src="../assets/icon_msg.png"
              style="width: 18px; height: 18px"
              alt=""
            />
            <div style="color: #333; font-size: 13px; margin-left: 5px">
              公司大事件
            </div>
          </div> -->
          <!-- <div
            style="
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 8px 36px;
              background: #f2f2f2;
            "
          >
            <img
              src="../assets/icon_msg.png"
              style="width: 18px; height: 18px"
              alt=""
            />
            <div style="color: #333; font-size: 13px; margin-left: 5px">
              企业宣传片
            </div>
          </div> -->
        </div>
        <div class="right">
          <div class="head">
            <div
              class="header-title"
              style="color: #0078cc; font-size: 17px; font-weight: bold"
            >
              公司简介
            </div>
            <div
              class="timer"
              style="
                font-size: 12px;
                color: #999;
                margin-top: 6px;
                border-bottom: 1px solid #333;
                padding-bottom: 5px;
              "
            >
              发布时间：2024-4-24 10:12 发布人：汇采药 访问量
            </div>
          </div>
          <div class="section" style="width: 100%">
            <div
              class=""
              style="
                color: #333;
                font-size: 22px;
                font-weight: bold;
                margin-top: 14px;
                text-align: center;
              "
            >
             公司简介
            </div>
            <img
              src="../assets/co_gate.pic.jpg"
              style="width: 66%; margin-left: 17%; margin-top: 15px"
              alt=""
            />
            <div
              class="h1"
              style="
                color: #333;
                font-weight: bold;
                margin-left: 12px;
                margin-top: 13px;
              "
            >
              聚焦终端 提升价值
            </div>

            <div style="margin-left: 36px; font-size: 16px; margin-top: 4px">
              汇仁医药成立于2001年，是汇仁集团投资兴办的一家集医药批发、零售、营销服务为一体的医药企业，现有员工3000余人，拥有汇仁医药贸易、汇仁堂连锁、上海汇仁、宁都汇仁、会昌顺达、于都天琢6家子公司，并斥资上亿元在赣州、宜春、上饶、吉安、抚州、信丰、东乡等地设立市/县级分公司，主营业务范围涵盖药品批发、零售、医疗器械、疫苗配送与储存等多个板块。2023年含税销售收入近100亿元，连续10余年跻身中国医药商业三十强。
            </div>
          </div>
          <div style="margin-left: 36px; font-size: 16px; margin-top: 24px">
            汇采药事业部是由江西汇仁医药贸易有限公司于2022年主导成立的全新部门。部门依托新型医药供应链服务平台---汇采药商城，专注区县连锁药房、单体药店提供：一站式采购、一站式管理、一站式运营的控销新模式。
          </div>
          <div style="margin-left: 36px; font-size: 16px; margin-top: 24px">
            部门在全国各省区市场联动，众多知名厂家优质产品加持，通过对政策研判、大数据分析和优质工业直连三者相结合的模式，再次定义中小型连锁药房，优质单体药房刚需差异化核心供应链。
          </div>
          <div
            class="h1"
            style="
              color: #333;
              font-weight: bold;
              margin-left: 12px;
              margin-top: 20px;
            "
          >
            科学引领 彰显实力
          </div>
      
          <div style="margin-left: 36px; font-size: 16px; margin-top: 24px">
            作为江西省大型医药商业公司，汇仁医药拥有良好的品牌形象，雄厚的资本实力，灵活的业务模式，一流的物流平台。公司斥资20亿打造集“药品结算、仓储配送、供应链服务、医药电商、零售药店管理”为一体的医药商业中心，总面积约200亩，储存量达55万件。仓库配置智能化立体库、拆零拣选、独立冷库等功能区，并引进仓库管理系统（WMS）、订单管理系统（OMS）、运输管理系统（TMS）等先进技术，真正实现精细、智能、高效的仓储及配送管理。 
          </div>
          <img
            src="../assets/stock.pic.jpg"
            style="width: 66%; margin-left: 17%; margin-top: 15px"
            alt=""
          />
          <div
            class="h1"
            style="
              color: #333;
              font-weight: bold;
              margin-left: 12px;
              margin-top: 20px;
            "
          >
            开拓发展 追求卓越
          </div>
          <div style="margin-left: 12px; font-size: 16px; margin-top: 2px">
            公司本着“仁者爱人，健康为本”的经营理念，以振兴中国医药事业为己任，通过前沿的营销理念，坚持市场为导向，积极开拓医药市场，以“质量第一，信誉至上”的宗旨服务于社会，并愿用更专业、更优质的服务，竭诚与新、老客户合作，共谋新的发展。  
          </div>
    
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  methods:{
        reload(){
            location.reload()
        }
    }
};
</script>
  
  <style lang='less' scoped>
    .active-class{
    &:hover{
        color:orangered !important
    }
  
  }
.container {
  width: 100%;
  text-align: left;
  .main {
    width: 100%;
    // display: flex;
    // justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    .tab-title {
      width: 100%;
      font-size: 10px;
      color: #000;
      margin-top: 15px;
    }
    .content {
      width: 100%;
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      .left {
        width: 200px;
        border: 1px solid #ddd;
        // height: 160px;
        // padding:10px 16px;
      }
      .right {
        width: 76%;
        border: 1px solid #ddd;
        margin-top: 20px;
        padding: 12px 15px;
        box-sizing: border-box;
      }
    }
  }
}
</style>