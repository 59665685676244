<template>
  <div class="container">
    <!-- <img style="width:100%;margin-top:13px;" src="../assets/news.png" alt=""> -->
    <div class="main">
      <div class="tab-title">
        您所在的位置：首页 > 最新资讯 >
        <span style="color: #0078cc">公司动态</span>
      </div>
      <div class="content">
        <div class="left">
          <div
            style="
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 8px 28px;
              border-bottom: 2px solid #0078cc;
            "
          >
            <img
              src="../assets/icon_heart.png"
              style="width: 18px; height: 18px"
              alt=""
            />
            <div
              style="
                font-weight: bold;
                color: #000;
                font-size: 15px;
                margin-left: 5px;
              "
            >
              汇采药咨询
            </div>
          </div>
          <div
            class="active-class"
            style="
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 8px 36px;
              border-bottom: 1px solid #ddd;
              cursor: pointer;
            "
            @click='reload'
          >
            <img
              src="../assets/icon_earth.png"
              style="width: 18px; height: 18px"
              alt=""
            />
            <div style=" font-size: 13px; margin-left: 5px">
              公司动态
            </div>
          </div>
          <!-- <div
            style="
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 8px 36px;
              border-bottom: 1px solid #ddd;
              background: #f2f2f2;
            "
          >
            <img
              src="../assets/icon_msg.png"
              style="width: 18px; height: 18px"
              alt=""
            />
            <div style="color: #333; font-size: 13px; margin-left: 5px">
              行业动向
            </div>
          </div> -->
          <!-- <div
            style="
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding: 8px 36px;
              background: #f2f2f2;
            "
          >
            <img
              src="../assets/icon_msg.png"
              style="width: 18px; height: 18px"
              alt=""
            />
            <div style="color: #333; font-size: 13px; margin-left: 5px">
              公益活动
            </div>
          </div> -->
        </div>
        <div class="right">
          <div class="head">
            <div
              class="header-title"
              style="color: #0078cc; font-size: 17px; font-weight: bold"
            >
              聚势谋新，应运而生的“汇采药”商城
            </div>
            <div
              class="timer"
              style="
                font-size: 12px;
                color: #999;
                margin-top: 6px;
                border-bottom: 1px solid #333;
                padding-bottom: 5px;
              "
            >
              发布时间：2024-4-24 9:37 发布人：汇采药 访问量
            </div>
          </div>
          <div class="section" style="width: 100%">
            <div
              class=""
              style="
                color: #333;
                font-size: 22px;
                font-weight: bold;
                margin-top: 14px;
                text-align: center;
              "
            >
              聚势谋新，应运而生的“汇采药”商城
            </div>
            <img
              src="../assets/main.pic.jpg"
              style="width: 66%; margin-left: 17%; margin-top: 15px"
              alt=""
            />
            <div
              class="h1"
              style="
                color: #333;
                font-weight: bold;
                margin-left: 12px;
                margin-top: 13px;
              "
            >
              汇采药
            </div>

            <div style="margin-left: 36px; font-size: 16px; margin-top: 4px">
              随着医药行业竞争加剧、政策监管趋严以及医疗、教育、房地产新三座大山出现，医药行业发展迎来新的发展趋势，终端为王、批零融合、线上线下互哺成为行业重点发展方向，至此，药品零售终端市场也正在从大流通和贴牌时代步入全新3.0时代，通过平台化操作，去除中间商，加强终端赋能，和客户形成长期稳定的利益共同体。传统控销模式陷入困境，新控销模式应运而生。
            </div>
          </div>
          <div style="margin-left: 36px; font-size: 16px; margin-top: 24px">
            汇采药事业部是由江西汇仁医药贸易有限公司于2022年主导成立的全新部门。部门依托新型医药供应链服务平台---汇采药商城，专注区县连锁药房、单体药店提供：一站式采购、一站式管理、一站式运营的控销新模式。
          </div>
          <div style="margin-left: 36px; font-size: 16px; margin-top: 24px">
            部门在全国各省区市场联动，众多知名厂家优质产品加持，通过对政策研判、大数据分析和优质工业直连三者相结合的模式，再次定义中小型连锁药房，优质单体药房刚需差异化核心供应链。
          </div>
          <div
            class="h1"
            style="
              color: #333;
              font-weight: bold;
              margin-left: 12px;
              margin-top: 20px;
            "
          >
            汇采药简介
          </div>
          <img
            src="../assets/app.pic.jpg"
            style="width: 66%; margin-left: 17%; margin-top: 15px"
            alt=""
          />
          <div style="margin-left: 36px; font-size: 16px; margin-top: 24px">
            汇采药是汇仁医药旗下数字化医药供应链服务平台。没有中间商赚差价，提供低价格，多产品流程化服务。集药品批发采购、仓储、智慧物流配送一体，凭借“互联网+OEM+控销”医药终端新模式，颠覆传统层次分销的采购模式，服务全国中小连锁药房、优质单体门店以及诊所卫生室等医疗机构，我们以互联网软件技术为依托、优质品类供应链为核心、运营赋能和数字化营销为特色，用全优无差价的品类，优质的服务，与广大的终端客户一起，迈向医药互联网+控销新时代。
          </div>
          <div
            class="h1"
            style="
              color: #cd2323;
              font-weight: bold;
              margin-left: 12px;
              margin-top: 20px;
            "
          >
            汇采药模式
          </div>
          <div style="margin-left: 12px; font-size: 16px; margin-top: 2px">
            通过软件控销商城，打造“互联网+OEM+控销”医药终端新模式。
          </div>
          <div
            class="h1"
            style="
              color: #cd2323;
              font-weight: bold;
              margin-left: 12px;
              margin-top: 20px;
            "
          >
            汇采药服务
          </div>
          <div style="margin-left: 12px; font-size: 16px; margin-top: 2px">
            汇采药作为汇仁医药强势打造的医药直供/控销平台，与国内众多优质生产企业建立品牌战略合作，现有热销品种800余个，涵盖各类基层用药，战略品种持续入驻，自主工业品牌产品即将上市。我们将以强大的品牌及品种优势打造中国领先的医药供应链服务平台，服务全国中小连锁药房、优质单体门店以及诊所卫生室等医疗机构，没有中间商赚差价，提供低价格，多产品优质服务，实现“一站式采购、一站式管理、一站式运营”，围绕"一站式采购、智能化服务”。控产品、控渠道、控区域、控价格，为终端会员企业提供高品质控销产品。
          </div>
          <div
            class="h1"
            style="
              color: #cd2323;
              font-weight: bold;
              margin-left: 12px;
              margin-top: 20px;
            "
          >
            汇采药优势
          </div>
          <div style="margin-left: 12px; font-size: 16px; margin-top: 2px">
            <div style="margin-top: 5px">1、企业实力</div>
            <div style="margin-top: 5px">
              企业实力雄厚，科工贸一体支撑平台稳定发展;
            </div>
            <div style="margin-top: 5px">2、市场控销</div>
            <div style="margin-top: 5px">
              市场绝对控销，不挂网不流通，区域保护严格:
            </div>
            <div style="margin-top: 5px">
              与国内众多优质生产企业建立品牌战略合作，品类资源丰富，有独家规格、剂型及市场销量大的优势品种;
            </div>
            <div style="margin-top: 5px">4、一站式采购</div>
            <div style="margin-top: 5px">
              产品直供终端、无中间商赚差价，提供一站式采购与智能化服务:
            </div>
            <div style="margin-top: 5px">5、精准赋能</div>
            <div style="margin-top: 5px">
              依托全国百强零售连锁---汇仁堂连锁,为客户提供门店运营管理精准赋能。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
    methods:{
        reload(){
            location.reload()
        }
    }
};
</script>
  
  <style lang='less' scoped>
  .active-class{
    &:hover{
        color:orangered !important
    }
  
  }
.container {
  width: 100%;
  text-align: left;
  .main {
    width: 100%;
    // display: flex;
    // justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    .tab-title {
      width: 100%;
      font-size: 10px;
      color: #000;
      margin-top: 15px;
    }
    .content {
      width: 100%;
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      .left {
        width: 200px;
        border: 1px solid #ddd;
        // height: 160px;
        // padding:10px 16px;
      }
      .right {
        width: 76%;
        border: 1px solid #ddd;
        margin-top: 20px;
        padding: 12px 15px;
        box-sizing: border-box;
      }
    }
  }
}
</style>