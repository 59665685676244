<template>
  <div class='container'>

    <img style="width:100%;margin-top:13px;" src="../assets/zhunzhong.jpg" alt="">
    <div class="list-content">
        <div class="left">
            <div class="title">最新资讯 News</div>
            <div class="content">
                <!-- <div class="document">
                    <div class="doc-left">
                        <span style="font-size:12px;color:red">>></span>
                        <span style="font-size:12px;color:#555;margin-left:2px">破局创新生·聚力熠未来 | 汇仁医</span>
                    </div>
                    <div class="timer" style="font-size:12px;color:#999">2024-03-05</div>
                </div> -->
            </div>
        </div>
        <div class="middle">
            <div class="title">人才招聘 Recruitment</div>
            <div class="content">
                <!-- <div class="document">
                    <div class="doc-left">
                        <span style="font-size:12px;color:red">>></span>
                        <span style="font-size:12px;color:#555;margin-left:2px">汇采药采购经理1名</span>
                    </div>
                    <div class="timer" style="font-size:12px;color:#999">2024-03-27</div>
                </div>

                <div class="document">
                    <div class="doc-left">
                        <span style="font-size:12px;color:red">>></span>
                        <span style="font-size:12px;color:#555;margin-left:2px">汇采药WMS系统管理员1名</span>
                    </div>
                    <div class="timer" style="font-size:12px;color:#999">2024-03-27</div>
                </div>
                <div class="document">
                    <div class="doc-left">
                        <span style="font-size:12px;color:red">>></span>
                        <span style="font-size:12px;color:#555;margin-left:2px">汇采药省区经理5名</span>
                    </div>
                    <div class="timer" style="font-size:12px;color:#999">2024-03-27</div>
                </div> -->
            </div>
        </div>
        <div class="right">
            <div class="title">公益活动 Public welfare</div>
            <div class="content">
                <!-- <div class="document">
                    <div class="doc-left">
                        <span style="font-size:12px;color:red">>></span>
                        <span style="font-size:12px;color:#555;margin-left:2px">破局创新生·聚力熠未来 | 汇仁医</span>
                    </div>
                    <div class="timer" style="font-size:12px;color:#999">2024-03-05</div>
                </div> -->
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    mounted(){
  
    }
}
</script>

<style lang='less' scoped>
.container{
    width:100%;
    .list-content{
        width:100%;
        display: flex;
        margin-top:10px;
        .left{
            width:31%;
            border:1px solid #ddd;
            border-radius: 2px;
            text-align: left;
            height:330px;
            padding:8px 15px;
            box-sizing:border-box;
            .title{
                color:#4981f2;
                font-weight: bold;
                font-size:16px;
            }
            .document{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top:6px;
                border-bottom:1px dashed #f2f2f2;
            }
        }
        .middle{
            width:31%;
            border:1px solid #ddd;
            border-radius: 2px;
            text-align: left;
            height:330px;
            padding:8px 15px;
            box-sizing:border-box;
            margin-left:3%;
            .title{
                color:#4981f2;
                font-weight: bold;
                font-size:16px;
            }
            .document{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top:6px;
                border-bottom:1px dashed #f2f2f2;
            }
        }
        .right{
            width:31%;
            border:1px solid #ddd;
            border-radius: 2px;
            text-align: left;
            height:330px;
            padding:8px 15px;
            box-sizing:border-box;
            margin-left:3%;
            .title{
                color:#4981f2;
                font-weight: bold;
                font-size:16px;
            }
            .document{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top:6px;
                border-bottom:1px dashed #f2f2f2;
            }
        }
    }
}
</style>