<template>
    <div class="container">
      <!-- <img style="width:100%;margin-top:13px;" src="../assets/news.png" alt=""> -->
      <div class="main">
        <div class="tab-title">
          您所在的位置：首页 > 联系我们 >
          <span style="color: #0078cc">联系我们</span>
        </div>
        <div class="content">
          <div class="left">
            <div
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 28px;
                border-bottom: 2px solid #0078cc;
              "
            >
              <img
                src="../assets/icon_heart.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div
                style="
                  font-weight: bold;
                  color: #000;
                  font-size: 15px;
                  margin-left: 5px;
                "
              >
                联系我们
              </div>
            </div>
            <div
            class="active-class"
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 36px;
                border-bottom: 1px solid #ddd;
                cursor: pointer;
              "
              @click='reload'
            >
              <img
                src="../assets/icon_earth.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div style="font-size: 13px; margin-left: 5px">
                联系我们
              </div>
            </div>
        
           
          
<!--        
            <div
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 36px;
                background: #f2f2f2;
              "
            >
              <img
                src="../assets/icon_msg.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div style="color: #333; font-size: 13px; margin-left: 5px">
                人才招聘
              </div>
            </div> -->
          </div>
          <div class="right">
            <img
                src="../assets/contact.pic.jpg"
                style="width: 66%; margin-left: 17%; margin-top: 15px"
                alt=""
              />
            <div class="head" style="margin-top:12px">
              <div
                class="header-title"
                style="color: #0078cc; font-size: 21px; font-weight: bold;border-bottom:1px solid #ddd;padding-bottom:6px"
              >
                联系我们
              </div>
              <div class="section" style="font-weight:bold;color:#fff;background:#da1820;font-size:23px;margin-top:26px;width:270px">
                江西汇采药医药有限公司
            </div>
            <div style='margin-top:8px'>
                电话：400-188-1898
            </div>
            <div style='margin-top:6px'>地址：江西省南昌市南昌高新技术产业开发区火炬大街628号综合楼3楼301-328室</div>
            <div style='margin-top:6px'>微信：</div>
            </div>
      
  
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  export default {
    methods:{
        reload(){
            location.reload()
        }
    }
  };
  </script>
    
    <style lang='less' scoped>
      .active-class{
    &:hover{
        color:orangered !important
    }
  
  }
  .container {
    width: 100%;
    text-align: left;
    .main {
      width: 100%;
      // display: flex;
      // justify-content: center;
      padding: 0 20px;
      box-sizing: border-box;
      .tab-title {
        width: 100%;
        font-size: 10px;
        color: #000;
        margin-top: 15px;
      }
      .content {
        width: 100%;
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        .left {
          width: 200px;
          border: 1px solid #ddd;
          // height: 160px;
          // padding:10px 16px;
        }
        .right {
          width: 76%;
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 12px 15px;
          box-sizing: border-box;
        }
      }
    }
  }
  </style>