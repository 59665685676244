<template>
    <div class="container">
      <!-- <img style="width:100%;margin-top:13px;" src="../assets/news.png" alt=""> -->
      <div class="main">
        <div class="tab-title">
          您所在的位置：首页 > 产品中心 >
          <span style="color: #0078cc">抗感染类</span>
        </div>
        <div class="content">
          <div class="left">
            <div
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 28px;
                border-bottom: 2px solid #0078cc;
              "
            >
              <img
                src="../assets/icon_heart.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div
                style="
                  font-weight: bold;
                  color: #000;
                  font-size: 15px;
                  margin-left: 5px;
                "
              >
                产品中心
              </div>
            </div>
            <div
            @click='reload'
            class="active-class"
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 36px;
                border-bottom: 1px solid #ddd;
                cursor: pointer;
              "
            >
              <img
                src="../assets/icon_earth.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              
              <div style=" font-size: 13px; margin-left: 5px">
                抗感染类
              </div>
            </div>
            <!-- <div
        
            class='active-class'
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 36px;
                border-bottom: 1px solid #ddd;
                background: #f2f2f2;
                cursor: pointer;
              "
            >
              <img
                src="../assets/icon_msg.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div style=" font-size: 13px; margin-left: 5px">
                心脑血管
              </div>
            </div> -->
            <!-- <div
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 36px;
                border-bottom: 1px solid #ddd;
                background: #f2f2f2;
              "
            >
              <img
                src="../assets/icon_msg.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div style="color: #333; font-size: 13px; margin-left: 5px">
                消化系统
              </div>
            </div> -->
            <!-- <div
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 36px;
                border-bottom: 1px solid #ddd;
                background: #f2f2f2;
              "
            >
              <img
                src="../assets/icon_msg.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div style="color: #333; font-size: 13px; margin-left: 5px">
                五官用药
              </div>
            </div> -->
            <!-- <div
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 36px;
                border-bottom: 1px solid #ddd;
                background: #f2f2f2;
              "
            >
              <img
                src="../assets/icon_msg.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div style="color: #333; font-size: 13px; margin-left: 5px">
                皮肤用药
              </div>
            </div> -->
            <!-- <div
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 36px;
                background: #f2f2f2;
              "
            >
              <img
                src="../assets/icon_msg.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div style="color: #333; font-size: 13px; margin-left: 5px">
                其他
              </div>
            </div> -->
          </div>
          <div class="right">
            <div class="head" style="width:100%;border-bottom:1px solid #555">
              <div
                class="header-title"
                style="color: #0078cc; font-size: 17px;width:70px; font-weight: bold;border-bottom:2px solid #0078cc"
              >
                抗感染类
              </div>
            
            </div>
            
            <div class="product-content" style="width:100%;border:1px solid #ddd;background:#f3fdff;margin-top:12px;padding:12px;box-sizing:border-box;display:flex;justify-content:space-between;align-items:center;flex-wrap:wrap">
                <div class="box" style="width:23%;height: 200px;" v-for='(item,idx) in list' :key='idx'>
                    <div class="img-box" style="background:#fff;border:1px solid #ddd;width:140px;height:140px;display:flex;justify-content:center;align-items:center">
                        <img :src="item.img" style="width:95%" alt="">
                    </div>
                    <div style="text-align:center;margin-top:6px;font-size:15px">
                        {{item.title}}
                    </div>
                </div>
            </div>
           
            
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  export default {

    data(){
        return{
            list:[{id:0,title:'头孢克洛分散片',img:require('../assets/med0001.png')},
            {id:1,title:'盐酸左氧氟沙星胶囊',img:require('../assets/med0002.png')},
            {id:2,title:'阿奇霉素胶囊',img:require('../assets/med0003.png')},
            {id:3,title:'阿莫西林胶囊',img:require('../assets/med0004.png')},
            {id:4,title:'罗红霉素胶囊',img:require('../assets/med0005.png')},
            {id:5,title:'克拉霉素胶囊',img:require('../assets/med0006.png')},
            {id:6,title:'阿莫西林颗粒',img:require('../assets/med0007.png')},
            {id:7,title:'罗红霉素干混悬剂',img:require('../assets/med0008.png')},
            {id:8,title:'富康唑胶囊',img:require('../assets/med0009.png')},
            {id:9,title:'罗红霉素颗粒',img:require('../assets/med0010.png')},
            {id:10,title:'诺氟沙星胶囊',img:require('../assets/med0011.png')},
            {id:11,title:'罗红霉素分散片',img:require('../assets/med0012.png')},
        ]
        }
     
    },
    methods:{
        reload(){
            location.reload()
        }
    }
  };
  </script>
    
    <style lang='less' scoped>
    .active-class{
    &:hover{
        color:orangered !important
    }
  
  }
  .container {
    width: 100%;
    text-align: left;
    .main {
      width: 100%;
      // display: flex;
      // justify-content: center;
      padding: 0 20px;
      box-sizing: border-box;
      .tab-title {
        width: 100%;
        font-size: 10px;
        color: #000;
        margin-top: 15px;
      }
      .content {
        width: 100%;
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        .left {
          width: 200px;
          border: 1px solid #ddd;
          // height: 160px;
          // padding:10px 16px;
        }
        .right {
          width: 76%;
        //   border: 1px solid #ddd;
          margin-top: 5px;
          padding: 12px 15px;
          box-sizing: border-box;
        }
      }
    }
  }
  </style>