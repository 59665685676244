import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,

    meta: {
      title: '汇采药医药有限公司'
    },
    children: [
      {
        path: '/',
        name: 'HomePage',
        component: require('@/views/HomePage').default,
        meta: {
          title: '汇采药医药有限公司'
        },
      },
      {
        path: '/culturl',
        name: 'Culturl',
        component: require('@/views/Culturl').default,
        
      },
      {
        path: '/news',
        name: 'News',
        component: require('@/views/News').default,
        
      },
      {
        path: '/product',
        name: 'product',
        component: require('@/views/product').default,
        
      },
      {
        path: '/operations',
        name: 'Operations',
        component: require('@/views/Operations').default,
        
      },
      {
        path: '/contact',
        name: 'Contact',
        component: require('@/views/Contact').default,
        
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '知冷暖-登录'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/createSchoolInfo',
    name: 'CreateSchoolInfo',

    component: () => import(/* webpackChunkName: "about" */ '../views/CreateSchoolInfo.vue')
  },
  {
    path: '/createClassInfo',
    name: 'CreateClassInfo',

    component: () => import(/* webpackChunkName: "about" */ '../views/CreateClassInfo.vue')
  },
  {
    path: '/schoolInfo',
    name: 'SchoolInfo',

    component: () => import(/* webpackChunkName: "about" */ '../views/SchoolInfo.vue')
  },
  {
    path: '/classInfo',
    name: 'ClassInfo',

    component: () => import(/* webpackChunkName: "about" */ '../views/ClassInfo.vue')
  },
  {
    path: '/teacherHome',
    name: 'TeacherHome',

    component: () => import(/* webpackChunkName: "about" */ '../views/TeacherHome.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
