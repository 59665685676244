<template>
  <div class="home">
    <div class="container">
      <div class="header">
        <img src="../assets/hcy_logo.png" style="widht:180px;height:70px" alt="">
      </div>
      <div class="tab-content">
        <div class="list" @click='chooseTab(item)' :class="activeId===item.id?'active-class' :''" v-for='(item,idx) in headerTabList' :key='idx'>{{item.title}}</div>
      </div>
        <div class="content">
          <router-view />
    <!-- <img style="width:100%;margin-top:13px;" src="../assets/home.png" alt=""> -->
  </div>


  <div class="footer">
    <img src="../assets/hcy_logo.png" style="widht:160px;height:60px;margin-top:-8px" alt="">
    <div class="right-footer">
      <div class="right-footer">
        <div class="co-info" style="color:#000">
          <div >
            版权所有：
          </div>
          <div style="color:#017dc4">
            汇采药医药有限公司
          </div>
          <div style="margin-left:100px">
            电话：
          </div>
          <div style="font-weight:normal;font-size:12px">
            400-188-1898
          </div>
          <div style="margin-left:60px;font-size:12px;color:#333;cursor: pointer;" class="active-class" @click="go">
            赣ICP备2024033745号-1
          </div>
        
        </div>
      </div>
      <div class="co-address" style="font-weight:bold;color:#000;font-size:14px;width:100%;text-align:left;margin-left:25px;margin-top:12px">
          公司地址：江西省南昌市南昌高新技术产业开发区火炬大街628号综合楼3楼301-328室
      </div>
    </div>
  </div>
  <div style="height:60px;position: fixed;bottom:10">

  </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: "Home",
  components: {},
  data(){
    return{
      activeId:0,
      headerTabList:[{id:0,title:'首页',path:'/'},{id:1,title:'最新资讯',path:'/news'},{id:2,title:'产品中心',path:'/product'},{id:3,title:'运营赋能',path:'/operations'},{id:4,title:'企业文化',path:'/culturl'},{id:5,title:'联系我们',path:'/contact'}]
    }
  },
   mounted(){
    const path = this.$route.path
    if(path==='/'){
          this.activeId = 0
    }else if(path==='/news'){
      this.activeId = 1
    }else if(path==='/product'){
      this.activeId = 2
    }else if(path==='/operations'){
      this.activeId = 3
    }else if(path==='/culturl'){
      this.activeId = 4
    }else if(path==='/contact'){
      this.activeId = 5
    }
    console.log(path,111)
   },
  methods:{
    chooseTab(item){
      this.activeId = item.id
      this.$router.push(item.path)
    },
    go(){
      window.location.href= 'https://beian.miit.gov.cn'
    }
   
  
  }
};
</script>
<style lang="less" >
   .active-class{
    &:hover{
        color:orangered !important
    }
  }
.home {
  width: 100%;
  display: flex;
  // height: 100vh;
  justify-content: center;
  // background: #f2f2f2;
  .container {
   width: 1000px;
    // padding: 16px 6px;
    box-sizing: border-box;
    // height: 100vh;
    background: #fff;
    .header{
      width:100%;
      height:70px;
      display: flex;
      justify-content: left;
      align-items: center;
      padding:0px 26px;
      box-sizing: border-box;
    }
    .tab-content{
      width:100%;
      height: 50px;
      background: #18459b;
      display: flex;
      justify-content: space-around;
      .list{
        width:102px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        color:#fff;
        font-weight: bold;
        font-size:16px;
        cursor: pointer;
      }
      .active-class{
        background: #4981f2 !important;
        color:#f1f1f1;
      }
      .list:hover{
        background: #4981f2;
        color:#f1f1f1;
      }
    }
    .footer{
      // width:100%;
      // bottom:40px;
      // position:fixed;
      margin-top:12px;
      height: 138px;
      border-top:1px solid #ddd;
      border-left:1px solid #ddd;
      border-right:1px solid #ddd;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      padding:46px 60px 68px 60px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    
      .right-footer{
        width:700px;
        margin-left:28px;
        margin-top:-10px;
        .co-info{
          display: flex;
          // justify-content: space-between;
          align-items: center;
          font-weight: bold;
          font-size:14px;
          .copy-right{
            color:#000;
            font-size:13px
          }
          .phone{
            color:#555;
            font-size:13px
          }
          
        }
      }
    }
  }

}
</style>