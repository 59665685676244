<template>
    <div class="container">
      <!-- <img style="width:100%;margin-top:13px;" src="../assets/news.png" alt=""> -->
      <div class="main">
        <div class="tab-title">
          您所在的位置：首页 > 汇采药运营赋能 >
          <span style="color: #0078cc">图文专区</span>
        </div>
        <div class="content">
          <div class="left">
            <div
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 28px;
                border-bottom: 2px solid #0078cc;
              "
            >
              <img
                src="../assets/icon_heart.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div
                style="
                  font-weight: bold;
                  color: #000;
                  font-size: 15px;
                  margin-left: 5px;
                "
              >
                汇采药运营赋能
              </div>
            </div>
            <div
            @click='reload'
            class="active-class"
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 36px;
                border-bottom: 1px solid #ddd;
                cursor: pointer;
              "
            >
              <img
                src="../assets/icon_earth.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div style=" font-size: 13px; margin-left: 5px">
                图文专区
              </div>
            </div>
            <!-- <div
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 36px;
                border-bottom: 1px solid #ddd;
                background: #f2f2f2;
              "
            >
              <img
                src="../assets/icon_msg.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div style="color: #333; font-size: 13px; margin-left: 5px">
                图文专区
              </div>
            </div> -->
         
         
           
            <!-- <div
              style="
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 8px 36px;
                background: #f2f2f2;
              "
            >
              <img
                src="../assets/icon_msg.png"
                style="width: 18px; height: 18px"
                alt=""
              />
              <div style="color: #333; font-size: 13px; margin-left: 5px">
                视频课程
              </div>
            </div> -->
          </div>
          <div class="right">
            <div class="head">
              <div
                class="header-title"
                style="color: #0078cc; font-size: 17px; font-weight: bold"
              >
                药品该如何吸引年轻人
              </div>
              <div
                class="timer"
                style="
                  font-size: 12px;
                  color: #999;
                  margin-top: 6px;
                  border-bottom: 1px solid #333;
                  padding-bottom: 5px;
                "
              >
                发布时间：2024-4-12 9:35 发布人：汇采药 访问量
              </div>
            </div>
            <div class="section" style="width: 100%">
              <div
                class=""
                style="
                  color: #333;
                  font-size: 22px;
                  font-weight: bold;
                  margin-top: 14px;
                  text-align: center;
                "
              >
               药店该如何吸引年轻人?
              </div>
              <img
                src="../assets/operations.png"
                style="width: 97%; margin-left: 1.5%; margin-top: 15px"
                alt=""
              />
            
  
      
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  export default {
    methods:{
        reload(){
            location.reload()
        }
    }
  };
  </script>
    
    <style lang='less' scoped>
    .active-class{
    &:hover{
        color:orangered !important
    }
  
  }
  .container {
    width: 100%;
    text-align: left;
    .main {
      width: 100%;
      // display: flex;
      // justify-content: center;
      padding: 0 20px;
      box-sizing: border-box;
      .tab-title {
        width: 100%;
        font-size: 10px;
        color: #000;
        margin-top: 15px;
      }
      .content {
        width: 100%;
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        .left {
          width: 200px;
          border: 1px solid #ddd;
          // height: 160px;
          // padding:10px 16px;
        }
        .right {
          width: 76%;
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 12px 15px;
          box-sizing: border-box;
        }
      }
    }
  }
  </style>